.wrapper{
    width: 100%;
    position: relative;
}
.suggestionInput{
    width: 100%;
    height: 30px;
    border: 1px solid lightgray;
}
.suggestionInput:focus-visible{
    outline: none;
}
.suggestion{
    position: absolute;
    width: 100%;
    background: white;
    z-index: 100;
    border: 1px solid lightgray;
    height: 152px;
    overflow: scroll;
    box-shadow: 1px 2px 9px lightgray;
}
.flexcenter{
    display: flex;
    justify-content: center;
}