@media screen and (max-width: 580px) {
  .gridBlocks {
    display: block !important;
  }

  .gridBlocks>div {
    min-width: 100%;
  }

  .subsModel {
    display: block !important;
    text-align: center;
  }

  .subsModal {
    max-width: 100% !important;
  }

  .flexModal {
    display: block !important;
  }

  .cardContainer {
    min-width: 100% !important;
    height: 100vh;
  }

  .containerLogin {
    width: 100% !important;
  }

  .tabledContainer {
    width: 275px !important;
    overflow: scroll;
  }
}

@media screen and (max-width:767px) {
  .dialogContainer>div>div {
    width: 100%;
  }

  .dialogContainer {
    padding: 20px;
  }

  .drawerBackground {
    position: absolute;
  }

  .barChartContainer {
    margin-top: 20%;
  }
}

@media screen and (max-width:400px) {
  .barChartContainer {
    margin-top: 50%;
  }
}