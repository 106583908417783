/* @import "shared/utils/colors.scss"; */
.paginationContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

}
.page_forward,
.page_prev,
.page_input {
  color: white;
  height: 43px;
  border: 0px;
  border-radius: 10px;
  font-family: "InterBold";
  width: 46px;
  background-color: #ff754c;
}
.page_input {
  background: none;
  text-align: center;
  color: #808191;
  border: 1px solid #f0f3f6;
  /* &:focus-visible {
    outline: none;
  } */
}

.page_prev {
  background-color: #f0f3f6;
}
.page_prev svg path {
  stroke: #808191;
}
.rotate {
  transform: rotate(180deg);
}
.page_title {
  font-family: "InterMedium";
  color: #808191;
  font-size: 12px;
}
@media screen and (max-width: 412px) {
  .page_title {
    display: none !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
