@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Manrope&family=Poppins:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Poppins:wght@500;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
  border: none !important;
}

* {
  font-family: 'Inter' !important;
}

/* .MuiFormControl-root.MuiTextField-root.css-136h9lv-MuiFormControl-root-MuiTextField-root {
  width: 200px !important;
}

.MuiTypography-root.MuiTypography-body1.css-geahnl-MuiTypography-root {
  width: 200px;
} */
.minHeight{
  min-height: 100vh;
}

