
.heading {
  font-family: 'Poppins' !important;
}
.flex{
  display: flex;
}
.spaceBetween{
  justify-content: space-between;
}
.itemCenter{
  align-items: center;
}
.MuiBox-root .css-1q1fzg4{
  width: 100% !important;
}

.MuiFormControl-root.MuiTextField-root.css-zwajzs-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.listItems>span, .css-tlelie-MuiListItemText-root>span,.css-o7ff5o-MuiListItemText-root > span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.dialogContainer>div>div {
  width: 100%;
  min-height: 400px;
}

.css-1ygcj2i-MuiTableCell-root{
  white-space: nowrap;
}
.error{
  font-size: 0.75rem;
    color: #d32f2f;
    margin-left: 14px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.flexContainer {
  display: flex;
  justify-content: space-between;
}

.flexCard {
  display: flex;
  justify-content: center;
}

.userTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #11142D;
  cursor: pointer;
}

.userName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #808191;
}

.detailContainer {
  border-bottom: 1px solid #E4E4E4;
  padding: 5%;
}

.MenuListing>div.MuiMenu-paper {
  border-radius: 11px;
  margin-top: 10px;

}

.MenuListing>div.MuiMenu-paper>ul {
  padding-top: 0px;
  padding-bottom: 0px;
}

.MenuListing>div.MuiMenu-paper>ul>li {
  padding: 1rem;
}

.MenuItems {
  font-family: 'Poppins' !important;
  font-size: 13px;
  text-align: left;
}

.gridStyle {
  border-radius: 12px;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

form.MuiBox-root.css-1tq3ans {
  display: flex;
}
.disc {
  margin: auto auto 24px;
  display: flex;
  border-radius: 50%;
  -webkit-box-align: center;
  align-items: center;
  width: 64px;
  height: 64px;
  -webkit-box-pack: center;
  justify-content: center;
}

.alertSuccess{
  display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.MuiAlert-message.css-1pxa9xg-MuiAlert-message{
  width: 100%;
}
.gLink{
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.openingHours{
  /* display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto; */
    display: flex;
    justify-content: space-between;
    /* width: 50%; */
    /* margin: 0 auto; */
    /* margin-left: 13px; */
    margin-right: 52px;
    margin-top: 12px;
    margin-bottom: 8px;
}
.openingHourContent{
  margin: 0 auto;
  /* width: 50%; */
}
.shopUpdateMask{
  width: 48px;
    outline: none;
    border: none;
}
.shopUpdateInput{
  font-size: 14px;
  margin-bottom: 1px;
}
.onboardingContainer{
  padding-left: 22px;
  padding-top: 13px;
}
.onbardForm{
  font-size: 20px;
  font-weight: 800;
  margin-left: 17px;
}