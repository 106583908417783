.background {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url("./assets/images/background.png") no-repeat center center fixed; */
  background: url("https://c.files.bbci.co.uk/C342/production/_88068994_thinkstockphotos-493881770.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}
.css-jcn4dz-MuiBadge-badge{
  width: max-content !important;
}
