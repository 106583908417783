.PaginateCard {
  display: flex;
  justify-content: start;
}

@media screen and (max-width: 900px) {
  .PaginateCard {
    width: 290px;
  }
}


/* @media (max-width: 768px) {
  .tabledContainer {
    overflow-x: scroll;
  }

  .tabledContainer td,
  .tabledContainer th {
    font-size: 14px;
  }

  .tabledContainer th,
  .tabledContainer td {
    padding: 8px;
  }
}

@media (max-width: 480px) {

  .tabledContainer td,
  .tabledContainer th {
    font-size: 12px;
  }
} */


.tableNameDiv {
  display: flex;
  align-items: center;

}
.flexCenter{
  justify-content: center;
}
.taleName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #11142D;
}

.tableUsername {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #808191;
  max-width: 200px;
}

.pagination-container {
  display: flex;
  margin-top: 3%;
  margin-left: 3%;
}

.tablePaginate,.paginateLeft {
  width: 48px;
  border: 1px solid #E4E4E4;
  justify-content: center;
  display: flex;
  padding: 1% 3% 1% 3%;
  border-radius: 7px;
  height: 48px;
  align-items: center;
  color: #808191;
  cursor: pointer;
  margin-right: 10px;
  height: 43px;
}
.paginateLeft{
  margin-left: 10px;
}
.d-flex{
  display: flex;
}


.paginateActive {
  background: #FF754C;
  color: white;
  border: 1px solid transparent;
}

@media screen and (max-width: 768px) {
  .pagination-container {
    flex-wrap: wrap;
    margin-left: 0;
    justify-content: center;
  }

  .tablePaginate {
    margin: 5px;
  }
}